import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import product_back from "../images/product_back.png";
import Roobot2 from "../images/RooBot2.png";
import right1 from "../images/ProdRight_1.png";
import left2 from "../images/ProdLeft_2.png";
import right3 from "../images/ProdRight_3.png";
import left4 from "../images/ProdLeft_4.png";
import {Helmet} from "react-helmet";
import SEO from "./seo";

export default function Product() {
    return <div>
        <SEO title="A personal digital coach"
             description="A roobrick is a smart performance coach that can streamline talent management"
        ></SEO>
        <div className="container-fluid p-0">
            <div className="row m-0">
                <div className="col-md-12 p-0">
                    <div style={{ backgroundImage: `url(${product_back})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPositionX: 'center' }}>
                        <div style={{ minHeight: 150 }}>
                            <Header/>
                        </div>
                        <div style={{ height: 120 }}>
                        </div>
                        <div>
                            <div className="home-hero-div">
                                <div className="home-hero-titlelight">Product</div>
                            </div>
                        </div>
                        <div style={{ height: 240 }}></div>
                    </div>
                </div>
            </div>
        </div>
        <div style={{ height: 50 }}></div>
        <div className="container-fluid">
            <div className="row m-5">
                <div className="col-md-6">
                    <img src={right1} align="right" className="flow-image"/>

                </div>
                <div className="col-md-6 order-md-first">
                    <div className="vertical-center">
                        <div className="textblock-left">
                            <div className="title">
                                A digital coach
                            </div>
                            <div className="body">
                                <p>
                                    <b>Like a personal trainer, but for work.</b>
                                </p>
                                <p>
                                    What if on your first day on the job, you were given a
                                    personal perfomance assistant whose sole job was to
                                    encourage your success?
                                </p>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <div className="row m-0">
                <div className="col-md-12">
                    <div style={{ height: 50 }}></div>
                </div>
            </div>
            <div className="row m-5">
                <div className="col-md-6">
                    <img src={left2} className="flow-image"/>
                </div>
                <div className="col-md-6">
                    <div className="vertical-center">
                        <div className="textblock-right">
                            <div className="title">
                                Understands the qualitative
                            </div>
                            <div className="body">
                                <p>
                                A roobrick gathers 360&deg; feedback from managers, peers, and direct reports
                                    continuously throughout the year
                                    (not just at review time).  It can keep responses anonymous,
                                    which also helps to improve their quality.
                                </p>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row m-0">
                <div className="col-md-12">
                    <div style={{ height: 50 }}></div>
                </div>
            </div>
            <div className="container-fluid p-0">
                <div className="row m-5">
                    <div className="col-md-6">
                        <img src={right3} align="right" className="flow-image"/>

                    </div>
                    <div className="col-md-6 order-md-first">
                        <div className="vertical-center">
                            <div className="textblock-left">
                                <div className="title">
                                    Understand the quantitative
                                </div>
                                <div className="body">
                                    <p>
                                        A roobrick learns from the applications you use every day
                                        to identify the patterns that drive your most important business metrics.
                                        Pre-built integrations to many common SaaS applications
                                        can get you up and running quickly.

                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="row m-0">
                <div className="col-md-12">
                    <div style={{ height: 50 }}></div>
                </div>
            </div>
            <div className="row m-5">
                <div className="col-md-6">
                    <img src={left4} className="flow-image"/>
                </div>
                <div className="col-md-6">
                    <div className="vertical-center">
                        <div className="textblock-right">
                            <div className="title">
                                Delivers just-in-time guidance
                            </div>
                            <div className="body">
                                <p>
                                    Whether the training content is native or comes from your learning management system,
                                    a roobrick can deliver it when and where it will have the greatest impact.

                                </p>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div style={{ height: 100 }}></div>

        <div className="roobot-2">
            <img src={Roobot2}/>
        </div>
        <br clear="all"/>

        <Footer />

    </div>
}